import { useLazyQuery } from "@apollo/client";
import { useContext, useEffect, useMemo, useState } from "react";
import { GET_PRESS_SUMMARY } from "../../../../../utils/graphql/queries";
import { determineParaCode } from "../../../../../components/utils/determineParaCodes";
import { CompetitionDetailsContext } from "../../../../../context/CompetitionDetailsContext";
import { Typography } from "@mui/material";

type PressProps = {
  tpeId: number | undefined;
};

const PressText = ({ tpeId }: PressProps) => {
  const { competitionId } = useContext(CompetitionDetailsContext);
  const [summaryData, setSummaryData] = useState<SummaryType[] | undefined>(
    undefined
  );
  const [hasRanks, setHasRanks] = useState<boolean>(false);

  const [getSummary, { loading, error }] = useLazyQuery(GET_PRESS_SUMMARY, {
    variables: {
      id: competitionId,
      tpeId: tpeId,
    },
    onCompleted: (data) => {
      setSummaryData(data.summary_type);
      if (data.summary_type.length > 0) setHasRanks(true);
      const numSummaryTypesWithRank = data?.summary_type?.filter(
        (summartype: SummaryType) =>
          summartype.ranks && summartype?.ranks?.length > 0
      ).length;
      if (numSummaryTypesWithRank === 0) setHasRanks(false);
    },
  });
  useMemo(() => {
    if (competitionId && tpeId) {
      getSummary({ variables: { id: competitionId, tpeId: tpeId } });
    }
  }, [tpeId, competitionId]);

  return (
    <>
      {loading && <div>Loading...</div>}
      {error && (
        <div>
          Generating text went wrong, try again or contact Swimify if problem
          persists.
        </div>
      )}
      {summaryData && hasRanks ? (
        summaryData.map(
          (summaryType: SummaryType) =>
            summaryType &&
            summaryType?.ranks &&
            summaryType?.ranks?.length > 0 && (
              <div style={{ marginBottom: 40 }}>
                <p style={{ margin: 0, paddingTop: 0 }}>
                  {
                    summaryType.round?.time_program_entries?.find(
                      (tpe: TimeProgramEntry) => tpe.id === tpeId
                    )?.name
                  }{" "}
                  {summaryType.name}
                </p>
                <br />
                <p style={{ margin: 0, paddingTop: 0, textWrap: "balance" }}>
                  {summaryType.ranks.map((rank: Rank) => (
                    <>
                      {`${rank.rank} ${rank?.lane?.competitor?.full_name} `}
                      {rank?.lane?.competitor?.is_para &&
                        summaryType.round?.event?.event_type &&
                        `${determineParaCode(
                          summaryType.round?.event?.event_type,
                          rank?.lane?.competitor
                        )}
                      `}
                      {!rank?.lane?.competitor?.is_relay &&
                        `${rank?.lane?.competitor?.birthday?.split("-")[0]} ${
                          rank?.lane?.competitor?.club?.name
                        } `}
                      {`${rank?.lane?.result_text} `}
                      {rank?.lane?.note &&
                        rank?.lane?.note !== "" &&
                        rank.lane.note}
                      <br />
                    </>
                  ))}
                </p>
              </div>
            )
        )
      ) : (
        <Typography
          fontWeight={500}
          fontSize={{ xs: "1rem", sm: "1.2rem" }}
          py={1}
          mt={1}
          mx={0.5}
        >
          No ranks are available. If event is not official, come back later when
          it is official to get the complete summary lists.
        </Typography>
      )}
    </>
  );
};

export default PressText;
