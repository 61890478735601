const getSubResultsChunks = (results, chunks) => {
  let arr = [];
  for (let i = 0; i < results?.length; i += chunks) {
    let chunk = results?.slice(i, i + chunks);
    arr.push(chunk);
  }
  return arr;
};

const competitorSplitsArr = (sub_results) => {
  let competitorSplits = [];
  let currentSplit = 0;
  for (let i = 0; i < sub_results?.length; i++) {
    if (sub_results[i]?.split_diff) {
      currentSplit += sub_results[i]?.split_diff;
    } else currentSplit = 0;

    competitorSplits.push({
      id: sub_results[i]?.id,
      currentSplit,
    });
  }
  return competitorSplits;
};

export default function relaySubArray(lane, results) {
  /* make sure to only include sub_results every 50 meters */
  let filteredSubResults = lane?.sub_results/* ?.filter(
    (result) => result?.done_at % 50 === 0
  ); */
  let numberOfResultsPerCompetitor =
    filteredSubResults?.length / lane?.relay_competitors?.length;
  /* order sub_results into sub-arrays matching to each corresponding competitor */
  let subResultChunks = getSubResultsChunks(
    filteredSubResults,
    numberOfResultsPerCompetitor
  );
  /* create new object with all necessary properties */
  let subResultsByCompetitor = lane?.relay_competitors?.map(
    (competitor, index) => {
      return {
        ...results[index],
        competitor: competitor?.competitor,
        sub_results: subResultChunks[index],
        competitor_split_values: competitorSplitsArr(subResultChunks[index]),
      };
    }
  );
  return subResultsByCompetitor;
}
